<template>
	<div :style="{ minHeight: minHeight }" class="lack flex-direction-column">
		<img :style="{ height: imgHeight, width: imgWidth }" :src="imgSrc"/>
		<span>{{ text }}</span>
	</div>
</template>

<script>
export default {
	props: {
		imgSrc: {
			type: String,
			default() {
				return ''
			}
		},
		minHeight: {
			type: String,
			default() {
				return ''
			}
		},
		imgWidth: {
			type: String,
			default() {
				return ''
			}
		},
		imgHeight: {
			type: String,
			default() {
				return ''
			}
		},
		text: {
			type: String,
			default() {
				return ''
			}
		}
	},
	data() {
		return {}
	},

	mounted() {
	},

	methods: {}
}
</script>

<style lang="less" scoped>
.lack {
	width: 100%;
	align-items: center;
	justify-content: center;

	img {
		width: 115px;
		height: 72px;
	}

	span {
		height: 20px;
		margin-top: 18px;
		color: #aaaaaa;
		line-height: 20px;
		text-align: center;
		font-size: 14px;
	}
}
</style>
